.MuiListItemText-root.details {
  display: inline-flex;
  justify-content: space-between;
}

.details .MuiListItemText-primary{
  padding-left:10px;
  font-size: 12px;
}
.details .MuiListItemText-secondary{

    font-size: 12px;
  }

.badge-stockage:hover {
  cursor:pointer;
}

.iconMobileNavbarBack {
  font-size: 45px !important;
  color: black;
}

.evenement {
  margin-top: 50px;
  width: max-content;
  margin: auto
}

.evenement:hover {
  cursor: pointer;
}

@media (max-width: 850px) {
  .evenement {
      width: 80%;
      margin: auto;
  }
}