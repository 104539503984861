body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.row {
  display: flex;
  margin-bottom: 2vh;
}

h1 {
  font-size: 2.5vw;
}

h2 {
  font-size: 2vw;
  color: #19BEE5;
}

.titleCCM {
  color: #19BEE5;
}

.center {
  text-align: center;
  display: block;
  margin: auto;
}

.rond {
  border: none; /* Supprime la bordure */
  border-radius: 0; /* Supprime l'arrondi */
  width: auto; /* Ajuste automatiquement la largeur */
  height: auto; /* Ajuste automatiquement la hauteur */
  padding: 0; /* Supprime le padding */
  display: flex; /* Conserve l'alignement flex pour centrer */
  margin: auto; /* Centre horizontalement */
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
}

.rond p {
  color: #19BEE5;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  line-height: normal; /* Ajuste la hauteur de ligne */
}


.comment-ca-marche {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.section {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.section div:not(.rond) p {
  max-width: 500px;
}
